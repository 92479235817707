import { replaceState } from '@/helpers';

interface ILoginProps {
  backUrl?: string;
  companyId?: UUID;
}

export const login = async ({ backUrl, companyId }: ILoginProps = {}) => {
  const url = new URL(process.env.VUE_APP_GATEKEEPER_ADDRESS!);
  const back = new URL(backUrl ?? window.location.href, process.env.VUE_APP_LEARNINGBOX_ADDRESS);

  url.searchParams.set('back', back.toString());

  if (companyId) url.searchParams.set('company_id', companyId);

  replaceState(url);
};
