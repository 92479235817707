import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import { SET_COMPANY } from '@/plugins/vuex/mutationTypes';

export const COMPANY_DEFAULT = {
  name: '',
  id: null,
};

const companyModule = {
  namespaced: true,

  state() {
    return {
      raw: COMPANY_DEFAULT,
    };
  },

  actions: {},

  mutations: {
    [SET_COMPANY](state, company) {
      state.raw = company;
    },
  },

  getters: {
    hasCompany: (state) => state.raw !== COMPANY_DEFAULT,
    companyName: (state) => (state.raw && state.raw.name ? state.raw.name : 'TalentTech'),
    companyId: (state) => state.raw?.id,
    companyAvatarUrl(_, getters) {
      const params = {
        uuid: getters.companyId,
        type: 'company',
        name: 'logo',
      };

      return getImageUrl(params);
    },
  },
};

export default companyModule;
