import { Route } from 'vue-router';
import { isEqual, pick } from '@/helpers';

export const getDomainFromURL = (location: Location) => new URL(location.href).hostname.split('.').slice(-2).join('.');

export function getDomainForCookies() {
  /**
   * Note: Получаем текущий домен.
   * Устанавливаем куки всегда на текущий домен и для всех сабдоменов.
   * Точку добавляем, чтобы куки распространялись на все сабдомены
   * Точку не добавляем для localhost
   * */
  let domain = `.${getDomainFromURL(window.location)}`;
  domain = domain.startsWith('.localhost') ? domain.slice(1) : domain;
  return domain;
}

/**
 * Сравниваем текущий роут и параметры для перехода на новый роут
 * @param {T extends Pick<Route, 'name' | 'params'>} currentRoute - текущий роут
 * @param {T extends Pick<Route, 'name' | 'params'>} otherRoute - параметры для другого роута
 * @return boolean
 */
// FIXME: Исправить неправильный тип. Тут должен быть RawLocation
export function isEqualRoutes<T extends Pick<Route, 'name' | 'params'>>(
  currentRoute: T,
  otherRoute: T,
): boolean {
  return isEqual(
    pick<T>(currentRoute, ['name', 'params']),
    pick<T>(otherRoute, ['name', 'params']),
  );
}

// TODO: дублируется в админке
/**
 * Меняет url (сделано для обхода cors)
 * @param {string} url
 * @param {string} bucketAddress
 * @param {string} proxyAddress
 * @return string
 */
export function proxyURLOnLocalhost({
  url,
  bucketAddress,
  proxyAddress,
}: {
  url: string;
  bucketAddress: string;
  proxyAddress: string;
}): string {
  if (process.env.VUE_APP_MODE === 'development') {
    return url.replace(bucketAddress, proxyAddress);
  }

  return url;
}

// TODO: дублируется в админке
/**
 * Загружает файл пользователю по url
 * @param {Body} resultFile
 * @param {URL} url
 * @param {Document} document
 * @param {string} name
 * @param {string} mime
 */
export async function downloadFileByUrl({
  resultFile,
  document,
  file: { name, mime },
}: {
  resultFile: Body;
  document: Document;
  file: { name: string; mime: string };
}) {
  const binaryData = [await resultFile.blob()];
  const currentUrl = URL.createObjectURL(new Blob(binaryData, { type: mime }));
  const a = document.createElement('a');
  a.href = currentUrl;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

/**
 * Вырезаем любые протоколы в начале ссылки
 * @param {String} v
 */
export function trimURL(v: string): string {
  return v.replace(/(^\w+:|^)\/\//, '');
}
