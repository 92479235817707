import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

export const buildBaseRoutes = (): RouteConfig[] => [
  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout.vue'),
    redirect: { name: Names.R_APP_LEARNING_TRACKS },
    children: [
      {
        path: 'error',
        name: Names.R_ERROR,
        component: () => import(/* webpackChunkName "error" */ '@/views/Errors/Error.vue'),
      },
      {
        path: 'errors/401',
        name: Names.R_ERROR_401,
        component: () => import(/* webpackChunkName "errors.401" */ '@/views/Errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: Names.R_ERROR_403,
        component: () => import(/* webpackChunkName "errors.403" */ '@/views/Errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        component: () => import(/* webpackChunkName "errors.404" */ '@/views/Errors/Error404.vue'),
      },
    ],
  },
];
