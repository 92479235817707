import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsScormRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'scorm/gateway',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_SCORM_GATEWAY,
    components: {
      default: () => import('@/views/App/AssignmentScormGateway.vue'),
    },
  },
  {
    path: 'scorm/player',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_SCORM_PLAYER,
    components: {
      default: () => import('@/views/App/AssignmentScormPlayer.vue'),
    },
  },
];
