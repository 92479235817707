import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsMultileveltestRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'multileveltest/gateway',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_GATEWAY,
    components: {
      default: () => import('@/views/App/AssignmentMultileveltestGateway.vue'),
    },
  },
  {
    path: 'multileveltest/intro',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO,
    components: {
      default: () =>
        import('@/views/App/Learning/Atoms/multileveltest/MultileveltestIntro/MultileveltestIntro.vue'),
    },
  },
  {
    path: 'multileveltest/result',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT,
    components: {
      default: () => import('@/views/App/Learning/Atoms/multileveltest/MultileveltestResult'),
    },
  },
];
