<template>
  <VAvatar
    data-test="tt-status-icon"
    :size="bgSize"
    :color="bgColor"
  >
    <VIcon
      data-test="tt-status-icon-icon"
      :size="iconSize"
      :color="iconColor"
    >
      <slot>
        {{ icon }}
      </slot>
    </VIcon>
  </VAvatar>
</template>

<script>
export default {
  name: 'TTStatusIcon',

  props: {
    icon: {
      type: String,
      default: 'fal fa-question',
    },

    iconColor: {
      type: String,
      default: 'tt-black lighten-2',
    },

    iconSize: {
      type: [Number, String],
      default: 14,
    },

    bgColor: {
      type: String,
      default: 'tt-black lighten-5',
    },

    bgSize: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
