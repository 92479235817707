function tos(response) {
  // If user does not accept terms of service,
  // every endpoint returns content from `/tos` endpoint.
  // So we need to check this case.
  if (
    typeof response.data === 'string'
    && response.data.length
    && response.config.url !== '/tos'
  ) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
}

export default tos;
