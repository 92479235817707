import VueYandexMetrika from 'vue-yandex-metrika';

const INCLUDE_YANDEX_METRIKA = process.env.VUE_APP_YANDEX_METRIKA_ON === 'true';

// eslint-disable-next-line import/prefer-default-export
export const setupYandexMetrics = (Vue, router) => {
  if (!INCLUDE_YANDEX_METRIKA) return;

  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    router,
    env: process.env.NODE_ENV,
    debug: process.env.VUE_APP_YANDEX_METRIKA_DEBUG === 'true',
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  });
};
