import { Category } from '../category';
import { UUID } from '../common';
import { IContentProvider } from '@/domains/content-provider';

export enum AtomStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  ARCHIVED = 'archived',
  DRAFT_DELETED = 'draft_deleted'
}

// TODO: добавить invalid
export enum AtomType {
  MULTILEVELTEST = 'multileveltest',
  MULTICONTENT = 'multicontent',
  TRACK = 'track',
  QUIZ = 'quiztest',
  SCORM = 'scorm',
  PROGRAM = 'program',
  UNKNOWN = 'unknown',
}

export enum AtomLicenseType {
  OWN = 'own',
  NONCOMMERCIAL = 'noncommercial'
}

export enum AtomSource {
  EXTERNAL = 'external',
  MIXED = 'mixed',
  MY_COMPANY = 'my_company'
}

export enum AtomSort {
  NAME_ASC = 'name_asc',
  UPDATED_AT_DESC = 'updated_at_desc'
}

export interface IAtomAuthor {
  /** @property ID автора */
  id: UUID;
  /** @property Имя автора */
  fullName: string;
}

interface IAtomTotals {
  /** @property Количество провайдеров атома */
  providers: number;
  /** @property Количество вопросов в атоме */
  questions?: number;
  /** @property Количество шагов в атоме */
  steps: number;
}

interface IAtomOwner {
  /** @property ID компании  */
  id: UUID;
  /** @property Название компании */
  name: string;
}

// TODO: добавить mainId, last_version_ref, main, draftEdited, imageId, обновить ownerId
export interface IAtom {
  /** @property ID атома */
  id: UUID;
  /** @property Тип атома: multicontent, multileveltest, program, track, quiztest, scorm */
  atomName: AtomType;
  /** @property Ссылка на контент в атоме */
  atomContentId: UUID | null;
  /** @property Заголовок атома контента */
  name: string | null;
  /** @property UUID изначальной версии атома */
  mainId: UUID;
  /** @property UUID актуальной версии атома */
  lastVersionRef: UUID | null;
  /** @property Признак того, что объект является изначальной версией атома */
  main: boolean;
  /** @property Признак того, что объект черновика был изменен от изначальной версии */
  draftEdited: boolean;
  /** @property Описание атома контента */
  description: string;
  /** @deprecated UUID картинки обложки атома - не использовать! Брать id картинки из lastVersionRef */
  imageId: string | null;
  /**
   * @property Источник атома контента: my_company, providers, mixed.
  *  Моя компания, Провайдеры, Различные источники.
  */
  source: AtomSource;
  /** @property Статус атома: draft, published, unpublished, archived, draft_deleted */
  status: AtomStatus;
  /** @property Длительность */
  duration: number | null;
  /** @property Тип лицензии: noncommercial, own. Коммерческая, некоммерческая */
  licenseType: AtomLicenseType;
  /** @property Список провайдеров атома */
  providers: IContentProvider[];
  /** @property Список категорий атома */
  categories: Category[];
  /** @property Количества провайдеров, вопросов и шагов */
  totals: IAtomTotals;
  /** @property Автор атома */
  author: IAtomAuthor;
  /** @property Владелец атома */
  owner: IAtomOwner;
  /** @property Дата и время создания */
  createdAt: DateTimeISO8601;
  /** @property Дата и время обновления */
  updatedAt: DateTimeISO8601;
}

export interface ICatalogueAtomFilters {
  atomNames: AtomType[],
  search: string,
  sort: AtomSort,
  providers: UUID[],
  categories: UUID[],
  durations: [Number | null, Number | null][],
}
