import { UUID } from '@/domains/common';
import { getDomainForCookies } from '@/helpers/url';
import { companyId as CompanyIdStorageService } from '../cookies';

interface IChangeCompanyProps {
  companyId?: UUID;
}

export const changeCompany = ({ companyId }: IChangeCompanyProps) => {
  if (CompanyIdStorageService.get() === companyId) return false;

  CompanyIdStorageService.remove({ domain: getDomainForCookies() });

  if (companyId) {
    CompanyIdStorageService.set(companyId, { domain: getDomainForCookies() });
  }

  return true;
};
