import Vue, { VNode } from 'vue';
import Names from '@/plugins/vue-router/routeNames';

export default Vue.extend({
  name: 'AppProvider',

  provide: {
    Names,
  },

  render() {
    return this.$slots.default as unknown as VNode;
  },
});
