import store from '@/plugins/vuex';
import { ADD_NOTIFICATION_ACTION } from '@/plugins/vuex/actionTypes';

const namespace = 'notifications/';

export enum NotifyType {
  COPY = 'copy'
}

interface INotificationData {
  title: string | null;
  content?: string ;
  linkExternal?: {
    to: string;
    text: string;
  };
  linkInternal?: {
    to: Location;
    text: string;
  };
}

export const success = (data: INotificationData, timeout = 3000) => {
  store.dispatch(namespace + ADD_NOTIFICATION_ACTION, {
    message: {
      type: 'success',
      notify: true,
      ...data,
    },
    timeout,
  });
};

export const info = (data: INotificationData, timeout = 3000) => {
  store.dispatch(namespace + ADD_NOTIFICATION_ACTION, {
    message: {
      type: 'info',
      notify: true,
      ...data,
    },
    timeout,
  });
};

export const error = (data: INotificationData, timeout = 3000) => {
  store.dispatch(namespace + ADD_NOTIFICATION_ACTION, {
    message: {
      type: 'error',
      notify: true,
      ...data,
    },
    timeout,
  });
};
