import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsQuizRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'quiztest',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_GATEWAY,
    components: {
      default: () => import('@/views/App/AssignmentQuizGateway.vue'),
    },
  },
  {
    path: 'quiztest/intro',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
    components: {
      default: () => import('@/views/App/AssignmentQuizIntro.vue'),
    },
  },
  {
    path: 'quiztest/result',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT,
    components: {
      default: () => import('@/views/App/AssignmentQuizResult.vue'),
    },
  },
  {
    path: 'quiztest/answers',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_ANSWERS,
    components: {
      default: () => import('@/views/App/AssignmentQuizAnswers.vue'),
    },
  },
];
