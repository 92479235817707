import store from '@/plugins/vuex';
import { ADD_PREV_PAGE_ACTION, REMOVE_PREV_PAGE_ACTION } from '@/plugins/vuex/actionTypes';
import Names from '@/plugins/vue-router/routeNames';

const namespace = 'pageHistory';

const savePageHistory = (to, from, next) => {
  const prevPage = store.getters[`${namespace}/previewPage`];

  // Note: не добавлять в историю R_APP_COURSE так как это корень для курсов
  // Note и возникает циклическое назад https://burning-heart.atlassian.net/browse/MNB-3480

  if (from.name && to.path !== prevPage && from.name !== Names.R_APP_COURSE) {
    // Note: новую страницу сохраняем
    store.dispatch(`${namespace}/${ADD_PREV_PAGE_ACTION}`, from.path);
  } else {
    // Note: если возвращаемся на предыдущую страницу, то удаляем её из списка
    store.dispatch(`${namespace}/${REMOVE_PREV_PAGE_ACTION}`);
  }
  next();
};

export default savePageHistory;
