import { ProviderFactory } from '../components';
import { contexts } from '../contexts';

export default function createContext(symbol: symbol, context: Record<string, any>) {
  const contextProvider = ProviderFactory(symbol, context);

  contexts.set(symbol, contextProvider);

  return contextProvider;
}
