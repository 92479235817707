import { NavigationGuard } from 'vue-router';
import { accessToken } from '@/services/cookies';

import Names from '@/plugins/vue-router/routeNames';
import { getDomainForCookies } from '@/helpers/url';

// eslint-disable-next-line prefer-destructuring
const VUE_APP_GATEKEEPER_ADDRESS = process.env.VUE_APP_GATEKEEPER_ADDRESS;

const hasToken: NavigationGuard = (to, from, next) => {
  const { name } = to;
  const isLogged = Boolean(accessToken.get());
  const guestList: string[] = [
    Names.R_ERROR_401,
    Names.R_ERROR_403,
    Names.R_ERROR_404,
  ];

  if (typeof name === 'string' && guestList.includes(name)) {
    return next();
  }

  if (!isLogged) {
    accessToken.remove({ domain: getDomainForCookies() });

    const url = new URL(`/?back=${window.location.href}`, VUE_APP_GATEKEEPER_ADDRESS);
    return globalThis.location.replace(url.href);
  }

  return next();
};

export default hasToken;
