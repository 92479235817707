import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

export const buildProfileRoutes = (): RouteConfig[] => [
  {
    path: 'profile',
    name: Names.R_APP_PROFILE,
    components: {
      nav: () => import('@/components/app/AppNav.vue'),
      'aside-left': () => import('@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName: "[request]" */ '@/views/App/Profile/Profile.vue'),
    },
  },
];
