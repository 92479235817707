
import {
  defineComponent, PropType, toRefs, VNode,
} from 'vue';
import { shouldBeNode } from '@/helpers';
import useFeatureFlag from '../composables/useFeatureFlag';
import { IFeatureFlags } from '../types';

export default defineComponent({
  name: 'FeatureFlag',

  props: {
    name: {
      type: String as PropType<keyof IFeatureFlags>,
      required: true,
    },
  },

  setup(props) {
    const { name } = toRefs(props);
    const isEnabled = useFeatureFlag(name.value) ?? false;

    return { isEnabled };
  },

  /**
   * return #default or #if slot if flag isEnabled
   * and #else otherwise or empty VNode
   */
  render(createElement): VNode {
    const props = {};

    // prettier-ignore
    const slot = this.isEnabled
      ? this.$scopedSlots.if ?? this.$scopedSlots.default
      : this.$scopedSlots.else;

    return shouldBeNode(slot && slot(props)) ?? createElement('');
  },
});

