export const toggleSnack = 'toggleSnack';
export const toggleLoading = 'toggleLoading';
export const toggleNavDrawer = 'toggleNavDrawer';

export const SET_USER = 'SET_USER';
export const SET_COMPANY = 'SET_COMPANY';

export const ADD_PREV_PAGE = 'ADD_PREV_PAGE';
export const REMOVE_PREV_PAGE = 'REMOVE_PREV_PAGE';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
