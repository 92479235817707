import { render, staticRenderFns } from "./TTPrompt.vue?vue&type=template&id=fa19c348&scoped=true&"
import script from "./TTPrompt.vue?vue&type=script&lang=js&"
export * from "./TTPrompt.vue?vue&type=script&lang=js&"
import style0 from "./TTPrompt.vue?vue&type=style&index=0&id=fa19c348&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa19c348",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
