import { RouteConfig } from 'vue-router';
import Names from '../routeNames';

export const buildExternalRoutes = (): RouteConfig[] => [
  {
    path: '/wiki',
    name: Names.R_EXTERNAL_WIKI,
    beforeEnter() {
      window.location.href = `${process.env.VUE_APP_LEARNINGBOXWIKI_ADDRESS}`;
    },
  },
];
