import { VueConstructor } from 'vue';
import { FeatureFlag } from './components';
import { createContext } from './utils';
import { FlagsSymbol } from './symbols';

const Plugin = {
  install(Vue: VueConstructor, { flags = {} }) {
    const FeatureFlagsContextProvider = createContext(FlagsSymbol, { flags });

    Vue.component('FeatureFlag', FeatureFlag);
    Vue.component('FeatureFlagsContextProvider', FeatureFlagsContextProvider);
  },
};

export default Plugin;
