import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '@/plugins/vue-router/types';
import parseIntParam from '@/helpers/queryParams/parseIntParam';

export const buildLearningCatalogueRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => {
  return [
    {
      path: 'catalogue',
      name: Names.R_APP_LEARNING_CATALOGUE,
      components: {
        default: () => (features.atom_publication
          ? import('@/views/App/Learning/Catalogue/LearningCatalogue.vue')
          : import('@/views/App/Learning/Catalogue/LearningCatalogueLegacy.vue'))
        ,
      },
    },
    {
      path: 'catalogue/courses',
      name: Names.R_APP_LEARNING_CATALOGUE_COURSES,
      components: {
        default: () => import('@/views/App/Learning/Catalogue/Courses/LearningCatalogueCourses.vue'),
      },
    },
    {
      path: 'catalogue/lessons',
      name: Names.R_APP_LEARNING_CATALOGUE_LESSONS,
      components: {
        default: () => import('@/views/App/Learning/Catalogue/Lessons/LearningCatalogueLessons.vue'),
      },
    },
    {
      path: 'catalogue/tests',
      name: Names.R_APP_LEARNING_CATALOGUE_TESTS,
      components: {
        default: () => import('@/views/App/Learning/Catalogue/Tests/LearningCatalogueTests.vue'),
      },
    },
    {
      path: 'catalogue/recommendations',
      name: Names.R_APP_LEARNING_CATALOGUE_RECOMMENDATIONS,
      components: {
        default: () => import('@/views/App/Learning/Catalogue/Recommendations/LearningCatalogueRecommendations.vue'),
      },
    },
    {
      path: 'catalogue/:catalogueAtomId/preview',
      name: Names.R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW,
      components: {
        default: () => import('@/views/App/CatalogueAtomPreview.vue'),
      },
      props: {
        default: (route: Route) => ({
          catalogueAtomId: route.params.catalogueAtomId,
          assignmentUserId: parseIntParam(route.query.assignmentUserId),
        }),
      },
    },
  ];
};
