type IFeatureFlagDescription = {
  name: string
  defaultValue: boolean
  description: string
}

export const featureFlagsDescriptions: IFeatureFlagDescription[] = [
  {
    name: 'quiz',
    description: '',
    defaultValue: true,
  },
  {
    name: 'scorm',
    description: '',
    defaultValue: true,
  },
  {
    name: 'copy',
    description: '',
    defaultValue: true,
  },
  {
    name: 'remove',
    description: '',
    defaultValue: true,
  },
  {
    name: 'file_uploads',
    description: '',
    defaultValue: true,
  },
  {
    name: 'edit_publish_quiz',
    description: 'Отвечает за возможность редактирования опубликованного квиза',
    defaultValue: true,
  },
  {
    name: 'edit_publish_multilevel_test',
    description: 'Отвечает за возможность редактирования опубликованного мультиуровневого теста',
    defaultValue: true,
  },
  {
    name: 'edit_publish_multi_content',
    description: 'Отвечает за редактирование опубликованного мультиконтента',
    defaultValue: true,
  },
  {
    name: 'edit_publish_scorm',
    description: 'Отвечает за редактирование опубликованного скорма',
    defaultValue: true,
  },
  {
    name: 'usedesk',
    description: 'Для работы плагина usedesk',
    defaultValue: false,
  },
  {
    name: 'helpdesk',
    description: 'Для работы плагина helpdesk',
    defaultValue: true,
  },
  {
    name: 'course_with_hierarchy',
    description: 'Включает новый конструктор курсов в админке с иерархией',
    defaultValue: true,
  },
  {
    name: 'edit_course_with_hierarchy',
    description: 'флаг для редактирования курса с иерархией',
    defaultValue: true,
  },
  {
    name: 'multi_content_richtext',
    description: 'Отображение текстового редактора RICH_TEXT',
    defaultValue: true,
  },
  {
    name: 'atom_publication',
    description: 'Включит функционал отдельной публикации',
    defaultValue: false,
  },
  {
    name: 'atom_publication_access_level',
    // eslint-disable-next-line max-len,vue/max-len
    description: 'Включит функционал разграничения видимости материала в каталоге.\n- Всем\n- Только для назначения администратором. В каталоге не доступно',
    defaultValue: false,
  },
  {
    name: 'atom_publication_access_level_users',
    description: 'Добавит функционал разграничения видимости материала в каталоге: "Только определенным сотрудникам".',
    defaultValue: false,
  },
  {
    name: 'multi_content_text',
    description: 'Отображения TEXT для мультиконтента',
    defaultValue: false,
  },
  {
    name: 'new_atom_program',
    description: 'Флаг для скрытия функционала создания нового атома "Программа"',
    defaultValue: false,
  },
  {
    name: 'atom_publication_course',
    description: 'Для скрытия функционала прохождения курса в рамках отдельной публикации',
    defaultValue: false,
  },
];

type IFeatureFlagDescriptions = typeof featureFlagsDescriptions;

export type { IFeatureFlagDescriptions };
