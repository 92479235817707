import is from 'is_js';
import Names from '@/plugins/vue-router/routeNames';

// eslint-disable-next-line prefer-destructuring
const VUE_APP_GATEKEEPER_ADDRESS = process.env.VUE_APP_GATEKEEPER_ADDRESS;

function isMobileAllowed(route) {
  const allowedRoutes = [
    Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_EXTERNAL,
    Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT_EXTERNAL,
  ];
  return allowedRoutes.includes(route.name);
}

function isMobile(to, from, next) {
  if (isMobileAllowed(to)) {
    return next();
  }

  if (is.mobile() && is.not.tablet()) {
    const url = new URL('/mobile/learning', VUE_APP_GATEKEEPER_ADDRESS);
    return window.location.replace(url.href);
  }

  return next();
}

export default isMobile;
