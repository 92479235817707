export default {
  ratingFullStar: 'fas fa-star',
  ratingStarOutline: 'far fa-star',
  stopwatch: 'fal fa-stopwatch',
  stopwatchFilled: 'fas fa-stopwatch',
  clockFilled: 'fas fa-clock',
  clockBordered: 'fal fa-clock',
  check: 'fas fa-check-circle',
  video: 'fa fa-video',
  lock: 'fa fa-lock',
  user: 'fal fa-user',
  remove: 'fal fa-trash-alt',
  calendarSolid: 'fas fa-calendar-alt',
  plusButton: 'fa-solid fa-plus',
  arrowToTop: 'fal fa-arrow-to-top',
  graduationCapLight: 'fal fa-graduation-cap',
  play: 'fas fa-play',
  playSolid: 'fas fa-play',
  questionSquare: 'fas fa-question-square',
  questionSquareSolid: 'fas fa-question-square',
  prev: 'fal fa-chevron-left',
  next: 'fal fa-chevron-right',
  ellipsis: 'fal fa-ellipsis-h',
  exclamationCircle: 'fa fa-exclamation-circle',
  sortOrder: 'fal fa-sort-alt',
  image: 'fal fa-image',
  arrowCircleUp: 'fas fa-arrow-circle-up',
  graduationCap: 'fas fa-graduation-cap',
  analytics: 'fal fa-analytics',
  chevronCircleRight: 'fas fa-chevron-circle-right',
  cog: 'fal fa-cog',
  angleLeft: 'fal fa-angle-left',
  angleRight: 'fal fa-angle-right',
  star: 'fas fa-star',
  clock: 'fas fa-clock',
  clockRegular: 'far fa-clock',
  clockSolid: 'fas fa-clock',
  clockLight: 'fal fa-clock',
  fireRegular: 'fa-regular fa-fire',
  questionCircleSolid: 'fas fa-question-circle',
  timesCircle: 'fas fa-times-circle',
  checkCircle: 'fas fa-check-circle',
  checkCircleRegular: 'far fa-check-circle',
  checkCircleSolid: 'fas fa-check-circle',
  checkCircleLight: 'fal fa-check-circle',
  gripVertical: 'fa-grip-vertical',
  timesSolid: 'fas fa-times',
  timesLight: 'fal fa-times',
  checkSolid: 'fas fa-check',
  file: 'fal fa-file',
  filePdfLight: 'fal fa-file-pdf',
  filePowerPointLight: 'fal fa-file-powerpoint',
  fileExcelLight: 'fal fa-file-excel',
  fileWordLight: 'fal fa-file-word',
  fileMusicLight: 'fal fa-file-music',
  fileArchiveLight: 'fal fa-file-archive',
  link: 'fal fa-link',
  infoCircle: 'far fa-info-circle',
  infoCircleRegular: 'far fa-info-circle',
  infoCircleSolid: 'fas fa-info-circle',
  infoCircleLight: 'fal fa-info-circle',
  glasses: 'far fa-glasses',
  glassesRegular: 'far fa-glasses',
  glassesSolid: 'fas fa-glasses',
  glassesLight: 'fal fa-glasses',
  lockAlt: 'far fa-lock-alt',
  lockAltRegular: 'far fa-lock-alt',
  lockAltSolid: 'fas fa-lock-alt',
  lockAltLight: 'fal fa-lock-alt',
  arrowToTopSimple: 'fal fa-angle-up',
  expandAlt: 'fas fa-expand-alt',
  circleMinus: 'fas fa-minus-circle',
  upload: 'fal fa-upload',
  clipboardCheck: 'fal fa-clipboard-list-check',
  list: 'fal fa-list',
};
