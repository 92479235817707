import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

import hasChiefRole from '@/plugins/vue-router/guards/hasChiefRole';

export const buildManagerRoutes = (): RouteConfig[] => [
  {
    path: 'manager',
    name: Names.R_APP_MANAGER,
    redirect: {
      name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEES,
    },
    beforeEnter: hasChiefRole,
    components: {
      nav: () => import('@/components/app/AppNav.vue'),
      'aside-left': () => import('@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName: "[request]" */ '@/layouts/EmptyLayout.vue'),
    },
    children: [
      {
        name: Names.R_APP_MANAGER_ANALYTICS,
        path: 'analytics',
        redirect: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEES,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/layouts/EmptyLayout.vue'),
        },
        children: [
          {
            name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEES,
            path: 'employees',
            components: {
              // eslint-disable-next-line max-len,vue/max-len
              default: () => import(/* webpackChunkName: "[request]" */ '@/views/App/AnalyticsEmployees/AnalyticsEmployees.vue'),
            },
          },
          {
            name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW,
            path: 'employees/:employeeId',
            components: {
              default: () => import(/* webpackChunkName: "[request]" */ '@/views/App/Profile/Profile.vue'),
            },
          },
          {
            name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT,
            path: 'employees/:employeeId/result',
            components: {
              default: () => import('@/views/App/Learning/Result'),
            },
            children: [
              {
                name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_MULTILEVELTEST,
                path: 'multileveltest/:playerSessionId',
                components: {
                  default: () => import('@/components/multileveltest/MultileveltestResultInfo'),
                },
              },
              {
                name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ,
                path: 'quiztest/:playerSessionId',
                components: {
                  default: () => import('@/components/quiz/QuizResult'),
                },
              },
              {
                name: Names.R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS,
                path: 'quiztest/:playerSessionId/details',
                components: {
                  default: () => import('@/components/quiz/QuizResultDetails/QuizResultDetails.vue'),
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
