import { RouteConfig } from 'vue-router';

import Names from '@/plugins/vue-router/routeNames';

import { buildBaseRoutes } from '@/plugins/vue-router/routes/routes.base';
import { buildAppRoutes } from '@/plugins/vue-router/routes/routes.app';

import { BuildRoutesProps } from './types';
import { buildExternalRoutes } from './routes/routes.external';
import { buildAuthRoutes } from './routes/routes.auth';

export const buildRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  ...buildBaseRoutes(),
  ...buildAuthRoutes(),
  ...buildAppRoutes({ features }),
  ...buildExternalRoutes(),
  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];
