import { getImageUrl } from '@/helpers/gogha/getImageUrl';
import { SET_USER } from '@/plugins/vuex/mutationTypes';
import { UserRole } from '@/domains/user';

export const USER_DEFAULT = {
  id: null,
  canReadAnalytics: false,
  name: {
    first: 'Пользователь',
    middle: '',
    last: '',
  },
  positionName: '',
  roles: [{ role: 'user' }],
};

const userModule = {
  namespaced: true,

  state() {
    return {
      raw: USER_DEFAULT,
    };
  },

  actions: {},

  mutations: {
    [SET_USER](state, user) {
      state.raw = user;
    },
  },

  getters: {
    hasUser: (state) => state.raw !== USER_DEFAULT,
    hasUserRoles: (state) => 'roles' in state.raw,
    hasRoleName: (state, getters) => (roleName) => getters.userRoles.some((role) => role.role === roleName),
    hasAdminRole: (state, getters) => getters.hasRoleName('admin'),
    hasChiefRole: (state, getters) => getters.hasRoleName(UserRole.CHEIF),
    userRoles: (state, getters) => (getters.hasUserRoles ? state.raw.roles : []),
    userPosition: (state) => (state.raw && state.raw.positionName ? state.raw.positionName : ''),
    userPhoneNumber: (state) => state.raw?.phoneNumber,
    userEmail: (state) => (state.raw?.email || ''),
    userCanReadAnalytics: (state, { hasRoleName }) => (hasRoleName(UserRole.CHEIF) || hasRoleName(UserRole.ADMIN)),
    userName: (state) => (state.raw?.name.first || 'Пользователь'),
    userLastName: (state) => (state.raw?.name.last || ''),
    userMiddleName: (state) => (state.raw?.middle || ''),
    userId: (state) => state.raw?.id,
    userAvatarUrl(_, { userId }) {
      const params = {
        uuid: userId,
        type: 'user',
        name: 'avatar',
      };

      return getImageUrl(params);
    },
  },
};

export default userModule;
