/* eslint-disable prefer-promise-reject-errors,func-names */
const apiRequestInterface = function (transport, response = (arg) => arg, error = (arg) => arg) {
  return function (config) {
    return transport(config)
      .then((r) => Promise.resolve(response(r)))
      .catch((err) => Promise.reject(error(err)));
  };
};

export default apiRequestInterface;
