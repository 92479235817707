import { replaceState } from '@/helpers/replaceState';
import { getDomainForCookies } from '@/helpers/url';
import { accessToken, companyId } from '../cookies';

interface ILogoutProps {
  backUrl?: string;
}

export const logout = ({ backUrl }: ILogoutProps) => {
  accessToken.remove({ domain: getDomainForCookies() });
  companyId.remove({ domain: getDomainForCookies() });

  // NOTE: редиректим в гейткипер со ссылкой на текущую страницу
  // TODO(onBeforeBuildHook): добавить проверку на наличие переменной в билде
  const url = new URL(process.env.VUE_APP_GATEKEEPER_ADDRESS!);

  if (backUrl) url.searchParams.set('back', backUrl);

  replaceState(url);
};
