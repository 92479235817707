import { HttpMethod } from '@/services/api/types';
import { getUserId } from '@/helpers/getUserId';
import transport from './transport';
import {
  IFeature, IFeatureFlagsGetResponse, IUserWithIdStrategy, StrategyName,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const featureFlagsGet = async (): Promise<Record<string, boolean>> => {
  const { features }: IFeatureFlagsGetResponse = await transport({
    url: '/features',
    method: HttpMethod.GET,
  });

  const currentUserId = getUserId();

  const isFeatureEnabledForCurrentUser = (feature: IFeature) => {
    const hasUserWithIdStrategy = feature.strategies.find(
      (strategy) => strategy.name === StrategyName.USER_WITH_ID,
    ) as IUserWithIdStrategy | undefined;

    if (hasUserWithIdStrategy) {
      const userIds = hasUserWithIdStrategy.parameters.userIds.split(',').filter((user) => user);

      if (!userIds.length) return feature.enabled; // NOTE: стратегия может быть включена, но список пуст

      const currentUser = userIds.find((id) => id === currentUserId);
      return currentUser ? feature.enabled : false;
    }

    return feature.enabled;
  };

  return features.reduce((acc, curr) => Object.assign(
    acc,
    { [curr.name]: curr.enabled && isFeatureEnabledForCurrentUser(curr) },
  ),
  {});
};
