<template>
  <div id="app">
    <FeatureFlagsContextProvider>
      <AppProvider>
        <VThemeProvider>
          <RouterView />
        </VThemeProvider>
      </AppProvider>
    </FeatureFlagsContextProvider>
  </div>
</template>

<script>
import AppProvider from '@/components/AppProvider';

export default {
  name: 'App',
  components: {
    AppProvider,
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>
