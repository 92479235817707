import { RouteConfig } from 'vue-router';
import { isArray } from 'lodash';
import Names from '@/plugins/vue-router/routeNames';
import { changeCompany, logout, login } from '@/services/auth';
import { replaceState } from '@/helpers';

export const buildAuthRoutes = (): RouteConfig[] => [
  {
    path: '/auth',
    name: Names.R_AUTH,
    redirect: {
      name: Names.R_AUTH_LOGIN,
    },
    children: [
      {
        path: 'login',
        name: Names.R_AUTH_LOGIN,
        beforeEnter(to, from) {
          const { back } = to.query;

          const backUrl = back
            ? isArray(back)
              ? back[0] ?? ''
              : back ?? ''
            : from.fullPath;

          login({ backUrl });
        },
      },
      {
        path: 'logout',
        name: Names.R_AUTH_LOGOUT,
        beforeEnter(to, from) {
          const { back } = to.query;

          const backUrl = back
            ? isArray(back)
              ? back[0] ?? ''
              : back ?? ''
            : from.fullPath;

          logout({ backUrl });
        },
      },
      {
        path: 'changeCompany/:companyId',
        name: Names.R_AUTH_CHANGE_COMPANY,
        beforeEnter(to, from) {
          changeCompany({ companyId: to.params.companyId });
          replaceState(from.fullPath);
        },
      },
    ],
  },
];
