import { computed } from 'vue';
import { warn } from '@/helpers';
import useFeatureFlags from './useFeatureFlags';
import { IFeatureFlags } from '../types';

export default function useFeatureFlag(name: keyof IFeatureFlags) {
  const { flags } = useFeatureFlags<IFeatureFlags>();

  if (!Object.hasOwnProperty.call(flags, name)) {
    warn(`Используемого флага - "${name.toString()}" не существует в системе`);
  }

  const flag = flags[name];

  return computed(() => flag ?? false);
}
