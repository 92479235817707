import { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { createRouter } from '@/plugins/vue-router/createRouter';
import hasToken from '@/plugins/vue-router/guards/hasToken';
import isMobile from '@/plugins/vue-router/guards/isMobile';
import savePageHistory from '@/plugins/vue-router/guards/savePageHistory';

interface Props {
  routes: RouteConfig[];
  mode?: 'history' | 'hash';
}

export const setupRouter = (localVue: VueConstructor, {
  routes, mode,
}: Props) => {
  localVue.use(VueRouter);

  const router = createRouter({
    mode: mode ?? 'history',
    base: process.env.BASE_URL,
    routes,
  });

  // Note: order is IMPORTANT
  router.beforeEach(isMobile);
  router.beforeEach(hasToken);
  router.beforeEach(savePageHistory);

  return router;
};
