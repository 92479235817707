import {
  defineComponent, provide, reactive,
  VNode,
} from 'vue';
import { shouldBeNode } from '@/helpers';

export default function ProviderFactory(symbol: symbol, __context: Record<string, any>) {
  return defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'Provider',

    setup() {
      const context = reactive(__context);

      provide(symbol, context);

      return { context };
    },

    render(createElement): VNode {
      const props = {};
      const slot = this.$scopedSlots.default;
      return shouldBeNode(slot && slot(props)) ?? createElement('');
    },
  });
}
