import {
  IAtom, AtomLicenseType, AtomSource, AtomStatus, AtomType,
} from './types';

export default class Atom implements IAtom {
  id: IAtom['id'];

  atomName: IAtom['atomName'];

  atomContentId: IAtom['atomContentId'];

  name: IAtom['name'];

  mainId: IAtom['mainId'];

  lastVersionRef: IAtom['lastVersionRef'];

  main: IAtom['main'];

  draftEdited: IAtom['draftEdited'];

  author: IAtom['author'];

  description: IAtom['description'];

  imageId: IAtom['imageId'];

  duration: IAtom['duration'];

  licenseType: IAtom['licenseType'];

  providers: IAtom['providers'];

  source: IAtom['source'];

  status: IAtom['status'];

  categories: IAtom['categories'];

  totals: IAtom['totals'];

  owner: IAtom['owner'];

  createdAt: IAtom['createdAt'];

  updatedAt: IAtom['updatedAt'];

  constructor(atom: Partial<IAtom>) {
    this.id = atom.id ?? '';
    this.atomName = atom.atomName ?? AtomType.TRACK;
    this.atomContentId = atom.atomContentId ?? null;
    this.name = atom.name ?? null;
    this.mainId = atom.mainId ?? '';
    this.lastVersionRef = atom.lastVersionRef ?? null;
    this.main = atom.main ?? true;
    this.draftEdited = atom.draftEdited ?? false;
    this.author = atom.author ? { ...atom.author } : { id: '', fullName: '' };
    this.description = atom.description ?? '';
    this.imageId = atom.imageId ?? null;
    this.duration = atom.duration ?? null;
    this.licenseType = atom.licenseType ?? AtomLicenseType.OWN;
    this.providers = atom.providers ?? [];
    this.source = atom.source ?? AtomSource.MY_COMPANY;
    this.status = atom.status ?? AtomStatus.DRAFT;
    this.categories = atom.categories ?? [];
    this.totals = atom.totals ? { ...atom.totals } : { providers: 0, questions: 0, steps: 0 };
    this.owner = atom.owner ? { ...atom.owner } : { id: '', name: '' };
    this.createdAt = atom.createdAt ?? '';
    this.updatedAt = atom.updatedAt ?? '';
  }
}
