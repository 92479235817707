import { VueConstructor } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import Router from 'vue-router';
import { getUserId } from '@/helpers/getUserId';
import { getCompanyId } from '@/services/cookieStorage';

const setup = ({
  vue,
  environment,
  dsn,
  router,
}: { vue: VueConstructor, environment: string; dsn: string; router: Router }) => {
  Sentry.init({
    Vue: vue,
    environment,
    dsn,
    initialScope: {
      tags: { company_id: getCompanyId() },
      user: { id: getUserId() },
    },
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1,
  });
};

export { setup };
