import { has } from 'lodash';

class EnvService {
  // eslint-disable-next-line class-methods-use-this
  public getEnv(key: string) {
    if (!has(process.env, key)) {
      throw new Error(`Env variable "${key}" not found! Please check .env.${process.env.NODE_ENV}`);
    }
    return process.env[key] || '';
  }
}

const envService = new EnvService();
export default envService;
