import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';
import { AtomType } from '@/domains/atom';

import { buildLearningAtomsTrackRoutes } from './routes.learning.atoms.track';
import { buildLearningAtomsQuizRoutes } from './routes.learning.atoms.quiz';
import { buildLearningAtomsMultileveltestRoutes } from './routes.learning.atoms.multileveltest';
import { buildLearningAtomsMulticontentRoutes } from './routes.learning.atoms.multicontent';
import { buildLearningAtomsScormRoutes } from './routes.learning.atoms.scorm';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'session/:playerSessionId/gateway/:atomType',
    name: Names.R_APP_LEARNING_SESSION_GATEWAY,
    redirect: (to) => {
      const map: Record<AtomType, Names> = {
        [AtomType.MULTICONTENT]: Names.R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_GATEWAY,
        [AtomType.MULTILEVELTEST]: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_GATEWAY,
        [AtomType.QUIZ]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_GATEWAY,
        [AtomType.SCORM]: Names.R_APP_LEARNING_SESSION_PLAYER_SCORM_GATEWAY,
        // TODO: Удалить R_APP_LEARNING_ASSIGNMENT_TRACK_STEP вместе с удалением флага atom_publication_course
        [AtomType.TRACK]: features.atom_publication_course
          ? Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_GATEWAY
          : Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP,
        [AtomType.PROGRAM]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
        [AtomType.UNKNOWN]: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
      };

      const { playerSessionId, atomType } = to.params as unknown as {
        playerSessionId: number;
        atomType: AtomType;
      };

      // TODO: Удалить вместе с удалением флага atom_publication_course
      const { assignmentUserId } = to.query as {
        assignmentUserId: string;
      };

      return {
        name: map[atomType],
        params: {
          playerSessionId: String(playerSessionId),
          assignmentId: assignmentUserId, // TODO: Удалить вместе с удалением флага atom_publication_course
          trackSessionId: String(playerSessionId), // TODO: Удалить вместе с удалением флага atom_publication_course
        },
      };
    },
  },

  {
    path: 'session/:playerSessionId/player',
    components: {
      default: () => import('@/layouts/PlayerLayout.vue'),
    },
    props: {
      default: (route: Route) => ({
        playerSessionId: Number(route.params.playerSessionId),
      }),
    },
    children: [
      ...buildLearningAtomsTrackRoutes({ features }),
      ...buildLearningAtomsQuizRoutes({ features }),
      ...buildLearningAtomsMultileveltestRoutes({ features }),
      ...buildLearningAtomsMulticontentRoutes({ features }),
      ...buildLearningAtomsScormRoutes({ features }),

      {
        path: ':atomType/preview',
        name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
        components: {
          default: () => import('@/views/App/AssignmentAtomPreview.vue'),
        },
        props: {
          default: (route: Route) => ({
            playerSessionId: Number(route.params.playerSessionId),
            atomType: route.params.atomType,
          }),
        },
      },

      {
        path: '(.*)',
        name: Names.R_APP_LEARNING_SESSION_PLAYER_UNKNOWN,
        components: {
          default: () => import('@/views/App/Assignments/AtomPlug.vue'),
        },
      },
    ],
  },
];
