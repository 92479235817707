import { IFeatureFlagDescription, IFeatureFlagsMapper } from './types';

export const getFeatureFlags = <T extends readonly IFeatureFlagDescription[]>(
  descriptions: T,
): IFeatureFlagsMapper<T> => descriptions.reduce(
    (acc, description) => {
      Reflect.set(acc, description.name, description.defaultValue);
      return acc;
    },
    {} as IFeatureFlagsMapper<T>,
  );
