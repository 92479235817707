import { VueConstructor, computed, getCurrentInstance } from 'vue';
import VueI18n, { LocaleMessages, DateTimeFormats } from 'vue-i18n';
import ru from '@/locales/ru.json';

// Note: https://kazupon.github.io/vue-i18n/guide/formatting.html
// TODO: Добавить поддержку [hot-module](https://kazupon.github.io/vue-i18n/guide/hot-reload.html#advanced-example)
// TODO: Может использовать yaml|yml для файлов локализации?
function loadLocaleMessages(): LocaleMessages {
  const messages: LocaleMessages = {
    ru,
  };

  return messages;
}

const dateTimeFormats: DateTimeFormats = {
  ru: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    shortMonth: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
  },
};

let i18nInstance: VueI18n;

function setupI18n(Vue: VueConstructor, options?: VueI18n.I18nOptions) {
  Vue.use(VueI18n);

  const locale = process.env.VUE_APP_I18N_LOCALE || 'ru';
  const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru';
  const messages = loadLocaleMessages();

  i18nInstance = new VueI18n({
    locale,
    fallbackLocale,
    messages,
    dateTimeFormats,
    warnHtmlInMessage: 'warn',
    pluralizationRules: {
      /**
       * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
       * @param choicesLength {number} an overall amount of available choices
       * @returns a final choice index to select plural word by
       */
      ru(choice) {
        // this === VueI18n instance, so the locale property also exists here
        if (choice === 0) {
          return 0;
        }

        const value = new Intl.PluralRules('ru').select(choice);

        switch (value) {
          case 'one':
            return 1;
          case 'few':
            return 2;
          default:
            return 3;
        }
      },
    },
    ...options,
  });

  return i18nInstance;
}

export function useI18n() {
  if (!i18nInstance) throw new Error('vue-i18n not initialized');

  const i18n = i18nInstance;

  const instance = getCurrentInstance();
  const vm = instance?.proxy;

  if (!vm) throw new Error('vue-i18n not initialized');

  const locale = computed({
    get() {
      return i18n.locale;
    },
    set(v: string) {
      i18n.locale = v;
    },
  });

  const t = vm.$t.bind(vm);
  const tc = vm.$tc.bind(vm);
  const d = vm.$d.bind(vm);
  const te = vm.$te.bind(vm);
  const n = vm.$n.bind(vm);
  const td = (key: string, fallback: string) => {
    return te(key)
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      ? t(key)
      : fallback;
  };

  return {
    locale,
    t,
    tc,
    d,
    te,
    n,
    td,
  };
}

// eslint-disable-next-line import/prefer-default-export
export { setupI18n };
