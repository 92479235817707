import { NavigationGuard } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import store from '@/plugins/vuex';

const hasChiefRole: NavigationGuard = (to, from, next) => {
  if (store.getters['user/hasChiefRole']) {
    next();
  } else {
    next({
      name: Names.R_ERROR_403,
    });
  }
};

export default hasChiefRole;
