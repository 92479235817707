import user from '@/plugins/vuex/modules/user';
import company from '@/plugins/vuex/modules/company';
import pageHistory from '@/plugins/vuex/modules/pageHistory';
import notifications from '@/plugins/vuex/modules/notifications';

const modules = {
  user,
  company,
  pageHistory,
  notifications,
};

export default modules;
