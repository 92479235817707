import { replaceState } from '@/helpers/replaceState';
import { logout } from '@/services/auth/logout';

const archivistModuleUrl = new URL(process.env.VUE_APP_ARCHIVIST_ADDRESS);

/**
 * Обязательный interceptor для обработки глобальных и локальный ошибок
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/FND/pages/1983938561
 */
async function onError(error) {
  const HTTP_FORBIDDEN = 403;
  const currentModuleUrl = new URL(window.location.href);

  // HACK: после того как fatal и нормальный json ответ приедет во все сервисы убрать fallback
  const fallback = error.response?.data === null;
  const isGlobalError = error.isAxiosError === true
    && [HTTP_FORBIDDEN].includes(error.response?.status)
    && (fallback || Boolean(error.response?.data?.error?.fatal) === true);

  if (isGlobalError) {
    const hasRetry = currentModuleUrl.searchParams.get('retry') === 'true';

    if (hasRetry) {
      // Глобальная ошибка в модуле и есть флаг retry
      replaceState(archivistModuleUrl);
    } else {
      // Глобальная ошибка в модуле и флага retry нет
      const backUrl = new URL(currentModuleUrl);
      backUrl.searchParams.set('retry', 'true');

      logout({ backUrl });
    }
  }

  return Promise.reject(error);
}

export { onError };
