import { getFullname, isEmpty } from '@/helpers';
import { IUserProfileBase } from '@/domains/user';
import EnvService from '@/services/EnvService';

export type HelpWidgetConfig = {
  profile: IUserProfileBase,
  enabled: boolean
}
let isInit = false;

/**
 * <!-- HELPDESKEDDY CHAT WIDGET START -->
 * <script src="https://cdn5.helpdeskeddy.com/js/modules/chat_widget/hde.js" data-lang="ru" id="hde-chat-widget"
 * data-host="servicedesk.potok.io" data-position="bottom-right" defer></script>
 * <!-- HELPDESKEDDY CHAT WIDGET END -->
 * @param config
 */
function setup({ profile, enabled }: HelpWidgetConfig) {
  const helpDeskSwitchedOn = EnvService.getEnv('VUE_APP_HELPDESK_ON');
  const helpDeskSrc = EnvService.getEnv('VUE_APP_HELPDESK_SCRIPT_URL');

  if (!helpDeskSwitchedOn) {
    console.warn('helpdesk отключен в переменных окружения');
    return;
  }

  if (helpDeskSwitchedOn && isEmpty(helpDeskSrc)) {
    console.error('Не могу подключить helpdesk. Не указан путь к файлу скрипта в env.VUE_APP_HELPDESK_SCRIPT_URL.');
    return;
  }

  if (!enabled) {
    console.warn('Helpdesk отключен');
    return;
  }

  if (isInit) {
    console.error('Helpdesk уже сконфигурирован!');
    return;
  }

  const userName = getFullname({
    firstname: profile.name.first,
    lastname: profile.name.last,
    middlename: profile.name.middle,
  });

  const script = document.createElement('script');

  script.id = 'hde-chat-widget';
  script.src = helpDeskSrc;
  script.dataset.host = 'servicedesk.potok.io';
  script.dataset.lang = 'ru';
  script.dataset.position = 'bottom-right';
  script.defer = true;

  script.dataset.userName = userName;
  script.dataset.userEmail = profile.email || '';

  document.body.appendChild(script);

  isInit = true;
}

export default setup;
