// Обновить подключение когда обновится библиотека @severgroup-tt/front.lib.vue.feature-flags
import { VueConstructor } from 'vue';
import { useFeatureFlag, useFeatureFlags } from './composables';
import { featureFlagsDescriptions } from './featureFlagsDescriptions';
import { getFeatureFlags } from './getFeatureFlagsDescriptions';
import Plugin from './plugin';
import { featureFlagsGet } from './services';

const DEFAULT_FLAGS = getFeatureFlags(featureFlagsDescriptions);

const setupFeatureFlags = async (localVue: VueConstructor) => {
  const flags = { ...DEFAULT_FLAGS };

  try {
    const _flags = await featureFlagsGet();

    Object.assign(flags, _flags);
  } catch (e: any) {
    console.error(e);
  }

  localVue.use(Plugin, { flags });

  return flags;
};

export default setupFeatureFlags;

export {
  setupFeatureFlags, useFeatureFlag, useFeatureFlags,
};
