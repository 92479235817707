<template>
  <TTNavigationDrawer
    app
    clipped
    :value="drawer"
    :width="width"
    class="pb-6"
  >
    <template #prepend>
      <slot name="prepend">
        <h6 class="px-12 mt-9 mb-6 tt-text-title-2">
          {{ title.text }}
        </h6>
      </slot>
    </template>

    <slot
      name="list"
      :items="items"
    >
      <VList
        nav
        class="pa-0"
      >
        <VListItemGroup>
          <VListItem
            v-for="item in items"
            :key="generateId(item.to)"
            :ripple="false"
            :to="item.to"
            :exact="item.exact"
            exact-active-class="custom-active"
            active-class="custom-active"
            dense
            class="px-12 pr-0 mb-6 custom-item"
          >
            <slot
              name="listItem"
              :item="item"
            >
              <VListItemIcon class="my-0 mr-7">
                <VIcon
                  :style="{ width : '24px', height : '24px' }"
                  size="19"
                >
                  {{ item.icon }}
                </VIcon>
              </VListItemIcon>

              <VListItemContent>
                <slot
                  name="listItemContentTitle"
                  :item="item"
                >
                  <VListItemTitle class="tt-text-body-2 font-weight-regular">
                    {{ item.title }}
                  </VListItemTitle>
                </slot>
              </VListItemContent>
            </slot>
          </VListItem>
        </VListItemGroup>
      </VList>
    </slot>

    <template #append>
      <slot name="append" />
    </template>
  </TTNavigationDrawer>
</template>

<script>
export default {
  name: 'AppNavDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },

    title: {
      type: [Object, String],
      required: true,
    },

    width: {
      type: [String, Number],
      default: 408,
    },

    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    generateId(v) {
      return Symbol(v);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-item {
  min-height: 0;
  height: 24px;

  &::before {
    opacity: 0 !important;
  }

  &:focus {
    &::before {
      opacity: 0 !important;
    }
  }
}

.custom-active {
  position: relative;

  &::before {
    opacity: 0 !important;
  }

  &::after {
    position: absolute;
    z-index: 1000;
    content: '';
    right: 0;
    width: 2px;
    height: 100%;
    background: map-get($tt-light-mono-100, 'base');
  }
}
</style>
