import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

export const buildLearningTracksRoutes = (): RouteConfig[] => [
  {
    path: 'tracks',
    name: Names.R_APP_LEARNING_TRACKS,
    components: {
      default: () => import('@/views/App/Learning/Tracks/LearningTracks.vue'),
    },
  },
  {
    path: 'tracks/empty',
    name: Names.R_APP_LEARNING_TRACK_EMPTY,
    components: {
      default: () => import('@/views/App/Assignments/AtomPlug.vue'),
    },
    props: {
      default: () => ({
        isTrack: true,
      }),
    },
  },
  {
    path: 'tracks/:trackSessionId',
    name: Names.R_APP_LEARNING_TRACK,
    components: {
      default: () => import('@/views/App/Course/CourseView/CourseView.vue'),
    },
    props: {
      default: (route: Route) => ({
        trackSessionId: Number(route.params.trackSessionId),
      }),
    },
  },
  {
    path: 'tracks/:catalogueAtomId/preview',
    name: Names.R_APP_LEARNING_ATOM_PREVIEW,
    components: {
      default: () => import('@/views/App/Course/CoursePreview/CoursePreview.vue'),
    },
    props: {
      default: (route: Route) => ({
        catalogueAtomId: route.params.catalogueAtomId,
      }),
    },
  },
];
