
import Vue, { PropType } from 'vue';
import { Route } from 'vue-router';
import { VBreadcrumbs } from 'vuetify/lib';

interface BreadcrumbsItem {
  text: string | number;
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  to?: string | Route;
}

export default Vue.extend({
  name: 'TTLBreadcrumbs',
  extends: VBreadcrumbs,
  props: {
    dataTest: { type: String, default: 'tt-breadcrumbs' },
    dataTestLabel: { type: String, default: '' },
    dataTestValue: { type: String, default: '' },
    items: {
      type: Array as PropType<BreadcrumbsItem[]>,
      default: () => [] as BreadcrumbsItem[],
    },
    divider: {
      type: String,
      default: '•',
    },
  },
});
