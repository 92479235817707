export default {
  'tt-color-amber-300': { base: '#FFD54F' },
  'tt-color-pink-50': { base: '#FCE4EC' },
  'tt-color-pink-200': { base: '#F48FB1' },
  'tt-color-pink-300': { base: '#F06292' },
  'tt-color-pink-400': { base: '#EC407A' },
  'tt-color-lightblue-50': { base: '#E1F5FE' },
  'tt-color-lightblue-400': { base: '#29B6F6' },
  'tt-color-gray-100': { base: '#F5F5F5' },
  'tt-color-gray-500': { base: '#9E9E9E' },
  'tt-color-green-50': { base: '#E8F5E9' },
  'tt-color-green-300': { base: '#81C784' },
  'tt-color-green-400': { base: '#66BB6A' },
  'tt-color-cyan-50': { base: '#E0F7FA' },
  'tt-color-cyan-200': { base: '#80DEEA' },
  'tt-color-cyan-300': { base: '#4DD0E1' },
  'tt-color-purple-50': { base: '#F3E5F5' },
  'tt-color-purple-200': { base: '#CE93D8' },
  'tt-color-indigo-300': { base: '#7986CB' },
  'tt-color-indigo-400': { base: '#5C6BC0' },
  'tt-color-bluegray-10': { base: '#F6F9FB' },
  'tt-color-bluegray-100': { base: '#CFD8DC' },
  'tt-color-bluegray-200': { base: '#B0BEC5' },
  'tt-color-red-400': { base: '#EF5350' },
  'tt-color-deeppurple-300': { base: '#9575CD' },
  'tt-color-orange-300': { base: '#FFB74D' },
  'tt-color-blue-300': { base: '#64B5F6' },
  'tt-color-purple-300': { base: '#BA68C8' },
};
