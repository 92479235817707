import { isNumber } from 'lodash';

export default function parseIntParam(param?: any) {
  if (!param) {
    return null;
  }
  const result = Number.parseInt(param, 10);
  if (!isNumber(result) || Number.isNaN(result)) {
    return null;
  }
  return result;
}
