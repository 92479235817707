/**
 * Заменит текущий шаг истории
 *
 * WARN: Если переданы `null` или `undefined` в качестве `url` текущий шаг
 * истории заменяется указанным состоянием
 *
 * @param url string | URL | null | undefined
 * @param state Any json object
 *
 * @return void
 */
export const replaceState = (url: string | URL | null | undefined, state?: JSONValue) => {
  if (state) {
    return window.history.replaceState(state, '', url);
  }

  if (!url) return window.location.reload();

  return window.location.replace(url);
};
