import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsTrackRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => {
  const routes: RouteConfig[] = [
    {
      path: 'track',
      components: {
        default: () => import('@/layouts/EmptyLayout.vue'),
      },
      children: [
        {
          path: '',
          name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK,
          redirect: {
            name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_GATEWAY,
          },
        },

        {
          path: 'gateway',
          name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_GATEWAY,
          components: {
            default: () => import('@/views/App/AssignmentTrackGateway.vue'),
          },
          props: {
            default: (route: Route) => ({
              playerSessionId: Number(route.params.playerSessionId),
            }),
          },
        },

        {
          path: 'player/steps/:stepId?',
          name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP,
          components: {
            default: () => import('@/layouts/AssignmentTrackPlayerLayout.vue'),
          },
          props: {
            default: (route: Route) => ({
              playerSessionId: Number(route.params.playerSessionId),
              stepId: route.params.stepId ?? undefined,
            }),
          },

          children: [
            //
            // Quiztest
            //
            {
              path: 'quiztest/:atomPlayerSessionId/intro',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_INTRO,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },
            // роут для quiztest player лежит в routes.learning.ts
            {
              path: 'quiztest/:atomPlayerSessionId/result',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_RESULT,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },
            {
              path: 'quiztest/:atomPlayerSessionId/result/view',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_RESULT_VIEW,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },

            //
            // Multileveltest
            //
            {
              path: 'multileveltest/:atomPlayerSessionId/intro',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_INTRO,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },
            // роут для multileveltest player лежит в routes.learning.ts
            {
              path: 'multileveltest/:atomPlayerSessionId/result',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_RESULT,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },

            //
            // Multicontent
            //
            {
              path: 'multicontent/:atomPlayerSessionId/player',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER,
              components: {
                // Note: просто заглушка, при необходимости можно изменить
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },

            //
            // Scorm
            //
            {
              path: 'scorm/:atomPlayerSessionId/player',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER,
              components: {
                default: () => import('@/layouts/EmptyLayout.vue'),
              },
            },

            //
            // Unknown
            // Заглушка для не поддерживаемого типа атома
            //
            {
              path: '(.*)',
              name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_UNKNOWN,
              components: {
                default: () => import('@/views/App/Assignments/AtomPlug.vue'),
              },
              props: {
                default: () => ({
                  isTrack: true,
                }),
              },
            },
          ],
        },
      ],
    },
  ];

  return routes;
};
