import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

import { buildLearningTracksRoutes } from './routes.learning.tracks';
import { buildLearningCatalogueRoutes } from './routes.learning.catalogue';
import { buildLearningAssignmentRoutes } from './routes.learning.assignment';
import { buildLearningAtomsRoutes } from './routes.learning.atoms';

import { BuildRoutesProps } from '../types';

export const buildLearningRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'learning',
    name: Names.R_APP_LEARNING,
    redirect: { name: Names.R_APP_LEARNING_TRACKS },
    components: {
      nav: () => import('@/components/app/AppNav.vue'),
      'aside-left': () => import('@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName: "[request]" */ '@/layouts/EmptyLayout.vue'),
    },
    children: [
      ...buildLearningTracksRoutes(),
      ...buildLearningCatalogueRoutes({ features }),
      ...buildLearningAssignmentRoutes({ features }),
      ...buildLearningAtomsRoutes({ features }),
    ],
  }, {
    /* eslint-disable max-len, vue/max-len */
    path: 'learning/assignments/:assignmentId/tracks/:trackSessionId/steps/:stepId/multileveltest/:playerSessionId/question',
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_QUESTION,
    components: {
      default: () => import('@/views/App/Assignments/Multileveltest/MultileveltestQuestion.vue'),
    },
    // Note Эта страница должна редиректить на новый плеер для мультитеста?
  },
  {
    /* eslint-disable max-len, vue/max-len */
    path: 'learning/assignments/:assignmentId/tracks/:trackSessionId/steps/:stepId/quiz/:playerSessionId/question/:questionId?',
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_QUESTION,
    components: {
      default: () => import('@/views/App/Assignments/Quiz/QuizQuestion.vue'),
      // Note Эта страница должна редиректить на новый плеер для квиза?
    },
  },
  {
    path: 'learning/assignments/:assignmentId/tracks/:trackSessionId/steps/:stepId/scorm/:playerSessionId/external',
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_EXTERNAL,
    components: {
      default: () => import('@/views/App/Assignments/Scorm/ScormExternal.vue'),
    },
  },
  {
    path: '/app/learning/assignments/:assignmentId/tracks/:trackSessionId/steps/:stepId/multicontent/:playerSessionId/:richTextId/external',
    name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT_EXTERNAL,
    components: {
      default: () => import('@/views/App/Assignments/Multicontent/MulticontentRichTextExternal.vue'),
    },
  },
  {
    path: '/app/learning/session/:playerSessionId/multileveltest/player',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_PLAYER,
    components: {
      default: () =>
        import('@/views/App/Learning/Atoms/multileveltest/MultileveltestQuestion/MultileveltestQuestion.vue'),
    },
    props: {
      default: (route: Route) => ({
        playerSessionId: Number(route.params.playerSessionId),
        questionId: Number(route.params.questionId),
      }),
    },
  },
  {
    path: 'learning/session/:playerSessionId/quiztest/player/:questionId?',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_PLAYER,
    components: {
      default: () => import('@/views/App/AssignmentQuizPlayer.vue'),
    },
    props: {
      default: (route: Route) => ({
        playerSessionId: Number(route.params.playerSessionId),
        questionId: Number(route.params.questionId),
      }),
    },
  },
  // прохождение вопросов в тестах track'a
  {
    path: '/app/learning/session/:playerSessionId/player/track/player/:stepId/multileveltest/:atomPlayerSessionId/player',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_PLAYER,
    components: {
      // Note: просто заглушка, при необходимости можно изменить
      default: () => import('@/layouts/EmptyLayout.vue'),
    },
  },
  {
    path: '/app/learning/session/:playerSessionId/player/track/player/:stepId/quiztest/:atomPlayerSessionId/player',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_PLAYER,
    components: {
      // Note: просто заглушка, при необходимости можно изменить
      default: () => import('@/layouts/EmptyLayout.vue'),
    },
  },
];
