import { accessToken } from '@/services/cookies';

type AccessToken = {
  jti: string,
  sub: string,
}

// TODO: удали за ненадобностью, разобраться почему не получается забирать id из vuex при первой загрузке экрана
function getUserId() {
  let userId;

  try {
    const token: string = accessToken.get();
    const [, payload] = token.split('.');
    const accessTokenValue = JSON.parse(atob(payload)) as AccessToken;
    userId = accessTokenValue.sub;
  } catch {
    // no-error
  }

  return userId;
}

export { getUserId, AccessToken };
