/* eslint-disable no-param-reassign */
export default class TopMind {
  eventList = [];

  isInit = false;

  constructor(scriptUrl, clientId, trackerId, options = []) {
    if (scriptUrl && clientId && trackerId) {
      this.eventList = [];
      if (!this.isInit) {
        this.isInit = true;
        const script = document.createElement('script');
        script.onload = () => {
          this.sendEvent('init', clientId, trackerId, ...options);
          this.eventList.forEach((event) => {
            this.sendEvent(...event);
          });
        };
        script.src = scriptUrl;
        document.head.appendChild(script);
      }
    }
  }

  setUserId(userId) {
    this.sendEvent('setUserId', userId);
  }

  sendEvent(...params) {
    if (this.isInit && window.$tmt) {
      window.$tmt(...params);
    } else {
      this.eventList.push(params);
    }
  }

  install(Vue) {
    const version = Number(Vue.version.split('.')[0]);

    if (version >= 3) {
      Vue.config.globalProperties.$tmt = this;
    } else {
      Vue.prototype.$tmt = this;
    }
  }
}
