import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { BuildRoutesProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildLearningAtomsMulticontentRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: 'multicontent/gateway',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_GATEWAY,
    components: {
      default: () => import('@/views/App/AssignmentMulticontentGateway.vue'),
    },
  },
  {
    path: 'multicontent/player',
    name: Names.R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_PLAYER,
    components: {
      default: () => import('@/views/App/AssignmentMulticontentPlayer.vue'),
    },
  },
];
