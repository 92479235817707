import isEqual from 'lodash/isEqual';
import { ADD_NOTIFICATION_ACTION, REMOVE_NOTIFICATION_ACTION } from '@/plugins/vuex/actionTypes';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '@/plugins/vuex/mutationTypes';

const notifications = {
  namespaced: true,

  state() {
    return {
      messages: [],
    };
  },

  actions: {
    [ADD_NOTIFICATION_ACTION]({ commit, dispatch }, { message, timeout }) {
      commit(ADD_NOTIFICATION, message);
      // если нет кнопок actions, то удаляем сообщения по истечению таймера
      if (timeout && !message.actions) {
        const timeoutId = setTimeout(() => {
          dispatch(REMOVE_NOTIFICATION_ACTION, message);
          clearTimeout(timeoutId);
        }, timeout);
      }
    },
    [REMOVE_NOTIFICATION_ACTION]({ commit }, payload) {
      commit(REMOVE_NOTIFICATION, payload);
    },
  },

  mutations: {
    [ADD_NOTIFICATION]({ messages }, payload) {
      if (!messages.find((i) => isEqual(i, payload))) {
        messages.unshift(payload);
      }
    },
    [REMOVE_NOTIFICATION](state, payload) {
      state.messages = state.messages.filter((msg) => !isEqual(msg, payload));
    },
  },

  getters: {},
};

export default notifications;
