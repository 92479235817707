import * as CookieStorageService from '@/services/cookieStorage';

async function authorization(config) {
  const token = await CookieStorageService.getAccessToken();

  if (token) {
    const headers = { Authorization: token };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default authorization;
