import { RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';

import { buildLearningRoutes } from './routes.learning';
import { buildProfileRoutes } from './routes.profile';
import { buildManagerRoutes } from './routes.manager';

import { BuildRoutesProps } from '../types';

export const buildAppRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => [
  {
    path: '/app',
    name: Names.R_APP,
    component: () => import('@/layouts/App/AppLayout.vue'),
    redirect: { name: Names.R_APP_LEARNING_TRACKS },
    children: [
      ...buildLearningRoutes({ features }),
      ...buildProfileRoutes(),
      ...buildManagerRoutes(),
    ],
  },
];
