/**
 * Добавит новый шаг истории
 *
 * WARN: Если переданы `null` или `undefined` в качестве `url` новый шаг
 * истории создается с указанным состоянием
 *
 * @param url string | URL | null | undefined
 * @param state Any json object
 *
 * @return void
 */
export const pushState = (url: string | URL | null | undefined, state?: JSONValue) => {
  if (state) {
    return window.history.pushState(state, '', url);
  }

  if (!url) return window.location.reload();

  return window.location.assign(url);
};
