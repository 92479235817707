import { getImageUrl } from '@/helpers/gogha/getImageUrl';

interface IOptions {
  size: `${string}x${string}`;
}

interface IDefaulsOptions {
  type: 'course';
  name: 'cover';
  size: null;
}

const DEFAULT_OPTIONS: IDefaulsOptions = {
  type: 'course',
  name: 'cover',
  size: null,
};

export default function getAtomCover(id: string, options?: IOptions) {
  const params = { ...DEFAULT_OPTIONS, ...options };

  // @ts-ignore
  return getImageUrl({
    uuid: id, ...params,
  });
}
