import jwt from 'jsonwebtoken';
import transport from '@/services/cookieStorage/js-cookie.transport';

// TODO: перестать использовать этот сервис, вместо него использовать @/services/cookies
function getDomainFromURL(location) {
  return new URL(location).hostname.split('.').slice(-2).join('.');
}

const names = {
  access: 'sgtt_access_token',
  company: 'sgtt_company_id',
};

export const setAccessToken = (token) => {
  /**
   * Note: Получаем текущий домен.
   * Устанавливаем куки всегда на текущий домен и для всех сабдоменов.
   * Точку добавляем, чтобы куки распространялись на все сабдомены
   * Точку не добавляем для localhost
   * */
  let domain = `.${getDomainFromURL(window.location)}`;
  domain = domain.startsWith('.localhost') ? domain.slice(1) : domain;

  transport.set(names.access, token, { domain });
};

export const getAccessToken = () => transport.getJSON(names.access);
export const removeAccessToken = () => transport.remove(names.access);

export const setCompanyId = (id) => {
  /**
   * Note: Получаем текущий домен.
   * Устанавливаем куки всегда на текущий домен и для всех сабдоменов.
   * Точку добавляем, чтобы куки распространялись на все сабдомены
   * Точку не добавляем для localhost
   * */
  let domain = `.${getDomainFromURL(window.location)}`;
  domain = domain.startsWith('.localhost') ? domain.slice(1) : domain;

  transport.set(names.company, id, { domain });
};
export const getCompanyId = () => transport.getJSON(names.company);
export const removeCompanyId = () => transport.remove(names.company);

export const encodeJWT = () => jwt.decode(getAccessToken());

export const userUUIDGet = () => encodeJWT().sub;
