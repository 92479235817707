import store from '@/plugins/vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';

export const success = (message: string) => store.commit(toggleSnack, {
  color: 'success',
  message,
}, { root: true });

export const error = (message: string) => store.commit(toggleSnack, {
  color: 'error',
  message,
}, { root: true });
