export enum StrategyName {
  USER_WITH_ID = 'userWithId', // TODO: добавить по мере надобности
  DEFAULT = 'default'
}

interface IEmptyStrategy {
  name: StrategyName.DEFAULT;
  parameters: {};
}

export interface IUserWithIdStrategy {
  name: StrategyName.USER_WITH_ID;
  parameters: {
    /**
     * @property {string} userIds - UUID's, разделенные запятой
     */
    userIds: string
  };
}

export interface IFeature {
  name: string;
  enabled: boolean;
  description: string;
  strategies: [(IEmptyStrategy | IUserWithIdStrategy)]; // TODO: добавить по мере надобности
}

export interface IFeatureFlagsGetResponse {
  features: IFeature[];
  version: number;
}
