/* eslint-disable max-len, vue/max-len */
enum Names {
  R_ERROR = 'error',
  R_ERROR_401 = 'error-401',
  R_ERROR_403 = 'error-403',
  R_ERROR_404 = 'error-404',

  R_AUTH = 'auth',
  R_AUTH_LOGIN = 'auth.login',
  R_AUTH_LOGOUT = 'auth.logout',
  R_AUTH_CHANGE_COMPANY = 'auth.changeCompany',

  R_APP = 'app',
  R_APP_PROFILE = 'app.profile',

  /* LEGACY */
  R_APP_DASHBOARD = 'app.dashboard',
  R_APP_EDUCATION = 'app.education',
  R_APP_MY_SKILLS = 'app.my-skills',
  R_APP_MY_SKILLS_ASSESSED = 'app.my-skills.assessed',
  R_APP_ANALYTICS_EMPLOYEE_SKILLS = 'app.analytics.employee-skills',

  R_APP_COURSES = 'app.courses',
  R_APP_COURSE = 'app.courses.course-id',
  R_APP_COURSE_TOPIC = 'app.courses.course-id.topics.topic-id',
  R_APP_COURSE_CONTENT = 'app.courses.course-id.contents.content-id',

  R_APP_GRADES_EMPLOYEE = 'app.grades.employee',
  R_APP_GRADES_SKILL_EMPLOYEE_REVIEW = 'app.grades.skill.employee.review',
  R_APP_GRADES_SKILL_EMPLOYEE_REVIEW_BY_INDICATORS = 'app.grades.skill.employee.review.by.indicators',
  R_APP_GRADES_EMPLOYEE_SKILL_VERIFICATION = 'app.grades.employee.skills.verification',

  // заглушка с переходом на главную
  R_APP_TO_TESTS = 'app.to-tests',
  /* /LEGACY */

  R_APP_LEARNING = 'app.learning',
  R_APP_LEARNING_CATALOGUE = 'app.learning.catalogue',
  R_APP_LEARNING_CATALOGUE_LESSONS = 'app.learning.catalogue.lessons',
  R_APP_LEARNING_CATALOGUE_TESTS = 'app.learning.catalogue.tests',
  R_APP_LEARNING_CATALOGUE_RECOMMENDATIONS = 'app.learning.catalogue.recommendations',
  R_APP_LEARNING_CATALOGUE_COURSES = 'app.learning.catalogue.courses',
  R_APP_LEARNING_TRACKS = 'app.learning.tracks',
  R_APP_LEARNING_TRACK = 'app.learning.track',
  R_APP_LEARNING_ATOM_PREVIEW = 'app.learning.tracks.preview',
  R_APP_LEARNING_TRACK_EMPTY = 'app.learning.track.empty',

  // Assignments
  R_APP_LEARNING_ASSIGNMENTS = 'app.learning.assignments',
  R_APP_LEARNING_ASSIGNMENT_RUN = 'app.learning.assignment.run',

  // Track
  R_APP_LEARNING_ASSIGNMENT_TRACK = 'app.learning.assignment.track',
  R_APP_LEARNING_ASSIGNMENT_TRACK_START = 'app.learning.assignments.track.start',
  R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW= 'app.learning.assignments.track.view',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP = 'app.learning.assignments.tracks.steps',
  // TODO: создать заглушку для не поддерживаемых типов атома
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_UNKNOWN = 'app.learning.assignments.tracks.steps.unknown',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT = 'app.learning.assignments.tracks.steps.multicontent',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST = 'app.learning.assignments.tracks.steps.multileveltest',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_VIEW = 'app.learning.assignments.tracks.steps.multileveltest.view',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT = 'app.learning.assignments.tracks.steps.multileveltest.result',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_QUESTION = 'app.learning.assignments.tracks.steps.multileveltest.question',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT_EXTERNAL = 'app.learning.assignments.tracks.steps.multicontent.external',

  // Quiz
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ = 'app.learning.assignments.tracks.steps.quiz',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_VIEW = 'app.learning.assignments.tracks.steps.quiz.view',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_QUESTION = 'app.learning.assignments.tracks.steps.quiz.question',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT = 'app.learning.assignments.tracks.steps.quiz.result',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT_VIEW = 'app.learning.assignments.tracks.steps.quiz.result.view',

  // Scorm
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW = 'app.learning.track.steps.scorm.view',
  R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_EXTERNAL = 'app.learning.track.steps.scorm.external',

  // Atoms
  R_APP_LEARNING_SESSION_GATEWAY = 'app.learning.session.gateway',
  R_APP_LEARNING_SESSION_PLAYER = 'app.learning.session.player',

  R_APP_LEARNING_SESSION_PLAYER_RUN = 'app.learning.session.player.run',

  R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW = 'app.learning.session.player.atom.preview',

  R_APP_LEARNING_SESSION_PLAYER_TRACK = 'app.learning.session.player.track',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PREVIEW = 'app.learning.session.player.track.preview',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_GATEWAY = 'app.learning.session.player.track.gateway',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_STEP = 'app.learning.session.player.track.player.step',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_UNKNOWN = 'app.learning.session.player.track.player.unknown',

  // TODO: переименовать QUIZ в QUIZTEST после перевода курса на новый флоу
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZ_VIEW = 'app.learning.session.player.track.player.quiz.view',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_INTRO = 'app.learning.session.player.track.player.quiztest.intro',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZ_PLAYER = 'app.learning.session.player.track.player.quiz.player',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_PLAYER = 'app.learning.session.player.track.player.quiztest.player',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZ_RESULT = 'app.learning.session.player.track.player.quiz.result',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_RESULT = 'app.learning.session.player.track.player.quiztest.result',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZ_RESULT_VIEW = 'app.learning.session.player.track.player.quiz.result.view',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_QUIZTEST_RESULT_VIEW = 'app.learning.session.player.track.player.quiztest.result.view',

  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST = 'app.learning.session.player.track.player.multileveltest',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_VIEW = 'app.learning.session.player.track.player.multileveltest.view',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_INTRO = 'app.learning.session.player.track.player.multileveltest.intro',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_PLAYER = 'app.learning.session.player.track.player.multileveltest.player',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTILEVELTEST_RESULT = 'app.learning.session.player.track.player.multileveltest.result',

  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT = 'app.learning.session.player.track.player.multicontent',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_VIEW = 'app.learning.session.player.track.player.multicontent.view',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_MULTICONTENT_PLAYER = 'app.learning.session.player.track.player.multicontent.player',

  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM = 'app.learning.session.player.track.player.scorm',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_VIEW = 'app.learning.session.player.track.player.scorm.view',
  R_APP_LEARNING_SESSION_PLAYER_TRACK_PLAYER_SCORM_PLAYER = 'app.learning.session.player.track.player.scorm.player',

  R_APP_LEARNING_SESSION_PLAYER_QUIZ_GATEWAY = 'app.learning.session.player.quiz.gateway',
  R_APP_LEARNING_SESSION_PLAYER_QUIZ_VIEW = 'app.learning.session.player.quiz.view',
  R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO = 'app.learning.session.player.quiz.intro',
  R_APP_LEARNING_SESSION_PLAYER_QUIZ_PLAYER = 'app.learning.session.player.quiz.player',
  R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT = 'app.learning.session.player.quiz.result',
  R_APP_LEARNING_SESSION_PLAYER_QUIZ_ANSWERS = 'app.learning.session.player.quiz.answers',

  R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_GATEWAY = 'app.learning.session.player.multileveltest.gateway',
  R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_VIEW = 'app.learning.session.player.multileveltest.view',
  R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO = 'app.learning.session.player.multileveltest.intro',
  R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_PLAYER = 'app.learning.session.player.multileveltest.player',
  R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_RESULT = 'app.learning.session.player.multileveltest.result',

  R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_GATEWAY = 'app.learning.session.player.multicontent.gateway',
  R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_VIEW = 'app.learning.session.player.multicontent.view',
  R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_PLAYER = 'app.learning.session.player.multicontent.player',

  R_APP_LEARNING_SESSION_PLAYER_SCORM_GATEWAY = 'app.learning.session.player.scorm.gateway',
  R_APP_LEARNING_SESSION_PLAYER_SCORM_VIEW = 'app.learning.session.player.scorm.view',
  R_APP_LEARNING_SESSION_PLAYER_SCORM_PLAYER = 'app.learning.session.player.scorm.player',

  R_APP_LEARNING_SESSION_PLAYER_UNKNOWN = 'app.learning.session.player.unknown',

  R_APP_LEARNING_CATALOGUE_ATOM_PREVIEW = 'app.learning.catalogue.atom.preview',

  // Manager
  R_APP_MANAGER = 'app.manager',
  R_APP_MANAGER_ANALYTICS = 'app.manager.analytics',
  R_APP_MANAGER_ANALYTICS_EMPLOYEES = 'app.manager.analytics.employees',
  R_APP_MANAGER_ANALYTICS_EMPLOYEE_VIEW = 'app.manager.analytics.employee.view',
  R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT = 'app.manager.analytics.employee.result',
  R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_MULTILEVELTEST = 'app.manager.analytics.employee.result.multileveltest',
  R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ = 'app.manager.analytics.employee.result.quiztest',
  R_APP_MANAGER_ANALYTICS_EMPLOYEE_RESULT_QUIZ_DETAILS = 'app.manager.analytics.employee.result.quiztest.details',

  // External
  R_EXTERNAL_WIKI = 'external.wiki',
}

export default Names;
