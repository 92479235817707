import { Route, RouteConfig } from 'vue-router';
import Names from '@/plugins/vue-router/routeNames';
import { AtomType } from '@/domains/atom';
import { BuildRoutesProps } from '@/plugins/vue-router/types';

export const buildLearningAssignmentRoutes = ({ features }: BuildRoutesProps): RouteConfig[] => {
  return [
    {
      path: 'assignments/:assignmentId',
      name: Names.R_APP_LEARNING_ASSIGNMENTS,
      components: {
        default: () => import('@/views/App/Assignments/Assignment.vue'),
      },
      props: {
        default: (route: Route) => ({
          assignmentId: Number(route.params.assignmentId),
        }),
      },
      children: [
        {
          path: 'run/:catalogueAtomMainId',
          name: Names.R_APP_LEARNING_ASSIGNMENT_RUN,
          components: {
            default: () => import('@/views/App/AssignmentRun.vue'),
          },
          props: {
            default: (route: Route) => ({
              assignmentId: Number(route.params.assignmentId),
              catalogueAtomMainId: route.params.catalogueAtomMainId,
            }),
          },
        },
        {
          path: 'tracks/:trackSessionId/',
          name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK,
          components: {
            default: () => import('@/views/App/Assignments/Course.vue'),
          },
          redirect: {
            name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
          },
          props: {
            default: (route: Route) => ({
              trackSessionId: Number(route.params.trackSessionId),
            }),
          },
          children: [
            {
              path: 'view',
              name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_VIEW,
              redirect: features.atom_publication_course ? (to) => {
                return {
                  name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
                  params: {
                    playerSessionId: String(to.params.trackSessionId),
                    atomType: AtomType.TRACK,
                  },
                };
              } : undefined,
              components: {
                default: () => import('@/views/App/Course/CourseView/CourseView.vue'),
              },
            },
            {
              path: 'steps/:stepId?',
              name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP,
              components: {
                default: () => import('@/views/App/Assignments/CoursePlayer.vue'),
              },
              props: {
                default: (route: Route) => ({
                  initialStepId: route.params.stepId,
                }),
              },
              children: [
                {
                  path: 'multicontent/:playerSessionId?',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
                  components: {
                    default: () => import('@/views/App/Learning/Assignment/AssignmentTrackStepMulticontent.vue'),
                  },
                },
                {
                  path: 'multileveltest/:playerSessionId?/view',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_VIEW,
                  components: {
                    default: () => import('@/views/App/Assignments/Multileveltest/MultileveltestView.vue'),
                  },
                },
                {
                  path: 'multileveltest/:playerSessionId?/result',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTILEVELTEST_RESULT,
                  components: {
                    default: () => import('@/views/App/Assignments/Multileveltest/MultileveltestResultView.vue'),
                  },
                },
                {
                  path: 'quiz/:playerSessionId',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ,
                  components: {
                    default: () => import('@/views/App/Assignments/Quiz/QuizWrapper.vue'),
                  },
                },
                {
                  path: 'quiz/:playerSessionId/view',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_VIEW,
                  components: {
                    default: () => import('@/views/App/Assignments/Quiz/QuizIntro.vue'),
                  },
                },
                {
                  path: 'quiz/:playerSessionId/result',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT,
                  components: {
                    default: () => import('@/views/App/Assignments/Quiz/QuizResultView.vue'),
                  },
                },
                {
                  path: 'quiz/:playerSessionId/result/view',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_QUIZ_RESULT_VIEW,
                  components: {
                    default: () => import('@/views/App/Assignments/Quiz/QuizAnswers.vue'),
                  },
                },
                {
                  path: 'scorm/:playerSessionId/view',
                  name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
                  components: {
                    default: () => import('@/views/App/Assignments/Scorm/ScormView/ScormView.vue'),
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'start/:catalogueTrackId',
          name: Names.R_APP_LEARNING_ASSIGNMENT_TRACK_START,
          components: {
            default: () => import('@/views/App/Course/CourseStart'),
          },
        },
      ],
    },
  ];
};
