import Vue from 'vue';
import { AxiosError } from 'axios';
import {
  extendWithVuetify,
  setupDayjs,
  setupDi,
  setupFeatureFlags,
  setupI18n,
  setupRouter,
  setupYandexMetrics,
  store,
  setupSentry,
} from '@/plugins';
import App from '@/App.vue';
import { getUserId } from '@/helpers/getUserId';
import { profileGet, companyGet } from '@/services/api/profile';
import { SET_COMPANY, SET_USER } from '@/plugins/vuex/mutationTypes';
import { buildRoutes } from './plugins/vue-router/buildRoutes';
import {
  changeCompany, hasToken, login, logout,
} from './services/auth';
import { getQueryParam } from './helpers/queryParams';
import { replaceState } from './helpers/replaceState';

Vue.config.productionTip = false;

async function runApp() {
  const COMPANY_ID_QUERY_PARAM_NAME = 'x_company_id';
  const companyId = getQueryParam(COMPANY_ID_QUERY_PARAM_NAME);

  if (!hasToken()) {
    login({ companyId });
    return;
  }

  if (companyId) {
    /**
     * Вырезаем параметр 'x_company_id' из урла
     */
    const backUrl = new URL(window.location.toString());
    backUrl.searchParams.delete(COMPANY_ID_QUERY_PARAM_NAME);

    changeCompany({ companyId });
    replaceState(backUrl.toString());
  }

  try {
    const [profileUserResponse, companyResponse] = await Promise.all([profileGet(), companyGet()]);

    await setupDi(Vue);

    const userId = getUserId();

    const mergedUserProfile = {
      ...profileUserResponse,
      id: userId,
    };

    // NOTE: Не убирай, даже если кажется, что ничего не ругается :)
    // @ts-ignore
    store.commit(`user/${SET_USER}`, mergedUserProfile);
    store.commit(`company/${SET_COMPANY}`, companyResponse);
  } catch (e: unknown) {
    console.error(e);

    if (e instanceof AxiosError && e.response?.status === 500) {
      logout({ backUrl: window.location.toString() });
    }
  } finally {
    const features = await setupFeatureFlags(Vue);
    const router = setupRouter(Vue, {
      routes: buildRoutes({ features }),
    });

    setupYandexMetrics(Vue, router);

    new Vue({
      name: 'TalentTech',
      i18n: setupI18n(Vue),
      router,
      store,
      vuetify: extendWithVuetify(Vue),
      created() {
        if (process.env.VUE_APP_SENTRY_ON) {
          setupSentry({
            vue: Vue,
            environment: process.env.VUE_APP_SENTRY_ENV!,
            dsn: process.env.VUE_APP_SENTRY_DSN!,
            router,
          });
        }

        setupDayjs(Vue);
      },
      render: (h) => h(App),
    }).$mount('#app');
  }
}

runApp();
