import transport from '@/services/api/profile/transport';
import * as methods from '@/services/api/constants';

const conf = { method: methods.HTTP_POST };

export const profileGet = (data = {}) => transport({ url: '/profile', method: methods.HTTP_GET, data });
export const profileUpdate = (data = {}) => transport({ url: '/profile', method: methods.HTTP_PUT, data });
export const companyGet = (data = {}) => transport({ url: '/company', method: methods.HTTP_GET, data });

export const profileCompanyGet = (data = {}) => transport({
  url: '/profile/companies',
  method: methods.HTTP_GET,
  data,
});

export const avatarSet = (data = {}) => transport({
  url: '/profile/avatar',
  ...conf,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  data,
});

export const profileRedirect = (data = {}) => transport({
  url: '/profile/redirect',
  ...conf,
  data,
});

export const sessionGet = (data = {}) => transport({
  url: '/session/get',
  ...conf,
  data,
});

export const sessionSet = (data = {}) => transport({
  url: '/session/set',
  ...conf,
  data,
});

export const contentProgressUpdate = (data = {}) => transport({
  url: '/content_progress',
  method: methods.HTTP_PUT,
  data,
});

export const appsGet = (data = {}) => transport({
  url: '/profile/apps',
  method: methods.HTTP_GET,
  data,
});

export const userGet = (id, data = {}) => transport({
  url: `/users/${id}`,
  method: methods.HTTP_GET,
  data,
});

export const getProfileChiefs = (data = {}) => transport({
  url: '/profile/chiefs',
  method: methods.HTTP_GET,
  data,
});

/*
 * Список компаний пользователя
 *
 * @example: profileCompaniesGet();
 */
export const profileCompaniesGet = ({ data = {}, ...options } = {}) => transport({
  url: 'profile/companies',
  method: methods.HTTP_GET,
  data,
  ...options,
});
