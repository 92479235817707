import * as CookieStorageService from '@/services/cookieStorage';

async function companyId(config) {
  const id = await CookieStorageService.getCompanyId();

  if (id) {
    const headers = { 'X-Company-Id': id };

    Object.assign(config.headers, headers);
  }

  return config;
}

export default companyId;
