import Vue, { PluginFunction, VueConstructor } from 'vue';
import BottleJS from 'bottlejs';
import { success as notifySuccess, error as notifyError, info as notifyInfo } from '@/common/notify';
import { error as snackError, success as snackSuccess } from '@/common/snack';
import { getUserId } from '@/helpers/getUserId';
import TopMind from '@/plugins/top-mind';
import support from '@/common/support';
import useDi from './composables';

const bottle = new BottleJS();

bottle.factory('snack', () => ({
  error: snackError,
  success: snackSuccess,
}));

bottle.factory('notify', () => ({
  success: notifySuccess,
  error: notifyError,
  info: notifyInfo,
}));

bottle.factory('support', () => support);

// TopMind
(() => {
  const userId = getUserId();
  if (userId) {
    const script = process.env.VUE_APP_TOPMIND_SCRIPT;
    const clientId = process.env.VUE_APP_TOPMIND_CLIENT_ID;
    const trackerId = process.env.VUE_APP_TOPMIND_TRACKER_ID;

    const topMind = new TopMind(
      script,
      clientId,
      trackerId,
      [{ initApiCall: false }],
    );

    topMind.setUserId(userId);

    bottle.factory('tmt', () => topMind);
  }
})();

const install: PluginFunction<Vue> = (vue) => {
  Object.defineProperty(vue.prototype, '$di', {
    get() {
      return bottle.container;
    },
  });
};

export function setupDi(vue: VueConstructor, installFn?: PluginFunction<Vue>) {
  if (installFn != null) {
    vue.use(installFn);
  } else {
    vue.use(install);
  }
}

export default bottle;
export { useDi };
