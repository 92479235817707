import { ADD_PREV_PAGE, REMOVE_PREV_PAGE } from '@/plugins/vuex/mutationTypes';
import { ADD_PREV_PAGE_ACTION, REMOVE_PREV_PAGE_ACTION } from '@/plugins/vuex/actionTypes';

const HISTORY_LIMIT = 100;

const pageHistory = {
  namespaced: true,

  state() {
    return {
      history: [],
    };
  },

  getters: {
    previewPage: ({ history }) => (history.length ? history[history.length - 1] : null),
  },

  mutations: {
    [ADD_PREV_PAGE]({ history }, path) {
      history.push(path);
      if (history.length > HISTORY_LIMIT) history.shift();
    },
    [REMOVE_PREV_PAGE]({ history }) {
      history.pop();
    },
  },

  actions: {
    [ADD_PREV_PAGE_ACTION]({ commit }, path) {
      commit(ADD_PREV_PAGE, path);
    },
    [REMOVE_PREV_PAGE_ACTION]({ commit }) {
      commit(REMOVE_PREV_PAGE);
    },
  },
};

export default pageHistory;
