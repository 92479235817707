import { PluginFunction, VueConstructor } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import objectSupport from 'dayjs/plugin/objectSupport';
import minmax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(quarterOfYear);
dayjs.extend(objectSupport);
dayjs.extend(minmax);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const primary = process.env.VUE_APP_I18N_LOCALE;
const fallback = process.env.VUE_APP_I18N_FALLBACK_LOCALE ?? 'ru';

const locale = primary ?? fallback;
dayjs.locale(locale);

const install: PluginFunction<Vue> = (vue) => {
  Object.defineProperty(vue.prototype, '$dayjs', {
    get() { return dayjs; },
  });
};

function setupDayjs(Vue: VueConstructor) {
  Vue.use(install);
}

// eslint-disable-next-line import/prefer-default-export
export { setupDayjs };
export default dayjs;
